import btc from "@assets/crypto/btc.svg";
import busd from "@assets/crypto/busd.svg";
import eth from "@assets/crypto/eth.svg";
import xrp from "@assets/crypto/xrp.svg";
import sol from "assets/crypto/sol.svg";
import usdt from "@assets/crypto/usdt.svg";
import ada from "@assets/crypto/ada.svg";
import xph from "@assets/crypto/xph.svg";
import matic from "@assets/crypto/matic.svg";
import link from "@assets/crypto/link.svg";
import doge from "@assets/crypto/doge.svg";
import tezos from "@assets/crypto/tezos.svg";
import okb from "@assets/crypto/okb.svg";
import kadena from "@assets/crypto/kadena.svg";
import orchid from "@assets/crypto/orchid.svg";
import dollar from "@assets/icons/dollar.svg";
import euro from "@assets/icons/euro.svg";
import replacement from "@assets/icons/euro.svg";
import pound from "@assets/icons/pound.svg";
// import bnb from "@assets/crypto/bnb.png";
// import wbtc from "@assets/crypto/wbtc.png";

const CURRENCIES = [
  { value: "usd", label: "USD", icon: dollar },
  { value: "eur", label: "EUR", icon: euro },
  { value: "gbp", label: "GBP", icon: pound },
  { value: "aud", label: "AUD", icon: dollar },
  { value: "cad", label: "CAD", icon: dollar },
];

const CRYPTO_CURRENCIES = [
  {
    value: "btc",
    label: "BTC",
    name: "Bitcoin",
    ids: "bitcoin",
    icon: btc,
    usd: 21182.5,
    exchange: {
      usd: 21182.5,
      eur: 19399.27,
      gbp: 16980.59,
      aud: 30374.62,
      cad: 28304.48,
    },
    change: 1.25,
    isFavorite: true,
    isTrending: false,
  },
  {
    value: "busd",
    label: "BUSD",
    name: "Binance",
    icon: busd,
    usd: 1.0,
    ids: "binancecoin",
    exchange: {
      usd: 1.0,
    },
    change: 0.18,
    isFavorite: false,
    isTrending: true,
  },
  {
    value: "eth",
    label: "ETH",
    name: "Ethereum",
    ids: "ethereum",
    icon: eth,
    usd: 1565.71,
    exchange: {
      usd: 1565.71,
    },
    change: -4.03,
    isFavorite: false,
    isTrending: false,
  },
  {
    value: "xrp",
    label: "XRP",
    name: "Ripple",
    ids: "ripple",
    icon: xrp,
    usd: 0.39,
    exchange: {
      usd: 0.39,
    },
    change: 0.05,
    isFavorite: true,
    isTrending: false,
  },
  {
    value: "sol",
    label: "SOL",
    name: "Solana",
    ids: "solana",
    icon: sol,
    usd: 23.17,
    exchange: {
      usd: 23.17,
    },
    change: -7.14,
    isFavorite: true,
    isTrending: true,
  },
  {
    value: "usdt",
    label: "USDT",
    name: "TETHER ERC20",
    icon: usdt,
    ids: "tether",
    usd: 1.0,
    exchange: {
      usd: 1.0,
    },
    change: 5.09,
    isFavorite: false,
    isTrending: true,
  },
  {
    value: "usdt",
    label: "USDT",
    name: "TETHER TRC20",
    icon: usdt,
    ids: "tether",
    usd: 1.0,
    exchange: {
      usd: 1.0,
    },
    change: 5.09,
    isFavorite: false,
    isTrending: true,
  },
  {
    value: "bnb",
    label: "BNB",
    name: "BNB",
    ids: "binance-bitcoin",
    icon: replacement,
    usd: 571.74,
    exchange: {
      usd: 571.74,
    },
    change: 5.09,
    isFavorite: false,
    isTrending: true,
  },
  {
    value: "bsc",
    label: "BSC",
    ids: "binance-bitcoin",
    name: "BSC",
    icon: replacement,
    usd: 571.74,
    exchange: {
      usd: 571.74,
    },
    change: 5.09,
    isFavorite: false,
    isTrending: true,
  },

  {
    value: "wbtc",
    label: "WBTC",
    name: "Bitcoin Fantom",
    ids: "fantom",
    icon: replacement,
    usd: 60804.47,
    exchange: {
      usd: 60804.47,
    },
    change: 5.09,
    isFavorite: false,
    isTrending: true,
  },
  {
    value: "ada",
    label: "ADA",
    name: "Cardano",
    ids: "cardano",
    icon: ada,
    usd: 0.35,
    exchange: {
      usd: 0.35,
    },
    change: 1.17,
    isFavorite: true,
    isTrending: true,
  },
  {
    value: "xph",
    label: "XPH",
    name: "Fantom",
    ids: "fantom",
    icon: xph,
    usd: 0.020018,
    exchange: {
      usd: 0.020018,
    },
    change: -0.58,
    isFavorite: false,
    isTrending: false,
  },
  {
    value: "matic",
    label: "MATIC",
    name: "Polygon",
    ids: "polygon",
    icon: matic,
    usd: 1.0482,
    exchange: {
      usd: 1.0482,
    },
    change: 3.92,
    isFavorite: true,
    isTrending: true,
  },
  {
    value: "link",
    label: "LINK",
    name: "1Chainlink",
    ids: "chainlink",
    icon: link,
    usd: 6.88,
    exchange: {
      usd: 6.88,
    },
    change: -6.11,
    isFavorite: true,
    isTrending: true,
  },
  {
    value: "doge",
    label: "DOGE",
    name: "Dogecoin",
    ids: "dogecoin",
    icon: doge,
    usd: 0.2,
    exchange: {
      usd: 0.2,
    },
    change: -2.04,
    isFavorite: false,
    isTrending: false,
  },
  {
    value: "tezos",
    label: "XTZ",
    name: "Tezos",
    ids: "tezos",
    icon: tezos,
    usd: 1.99,
    exchange: {
      usd: 1.99,
    },
    change: 5.19,
    isFavorite: false,
    isTrending: true,
  },
  {
    value: "okb",
    label: "OKB",
    name: "OKB",
    ids: "okb",
    icon: okb,
    usd: 3.99,
    exchange: {
      usd: 3.99,
    },
    change: 0.25,
    isFavorite: true,
    isTrending: true,
  },
  {
    value: "kadena",
    label: "KDA",
    name: "Kadena",
    ids: "kadena",
    icon: kadena,
    usd: 0.99,
    exchange: {
      usd: 0.99,
    },
    change: -0.13,
    isFavorite: false,
    isTrending: false,
  },
  {
    value: "orchid",
    label: "OXT",
    name: "Orchid",
    icon: orchid,
    ids: "orchid-protocol",
    usd: 1.99,
    exchange: {
      usd: 1.99,
    },
    change: 7.08,
    isFavorite: true,
    isTrending: true,
  },
];

const MERCHANTS = [
  { value: "visa", label: "Visa USD" },
  { value: "mastercard", label: "Mastercard USD" },
  { value: "paypal", label: "Paypal" },
  { value: "apple", label: "Apple Pay" },
  { value: "google", label: "Google Pay" },
];

export { CURRENCIES, CRYPTO_CURRENCIES, MERCHANTS };
